import React, { useState } from 'react';
import { Grid, TextField, Button, FormControl, FormGroup, InputLabel, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withdraw } from '../redux/slices/sliceActions';
import SnackBar from './snackBar';

const WithdrawModal = ({ setOpen }) => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.usersslice);
  const services = useSelector((state) => state?.usersslice.merchantservices);
  const merchantaccounts = useSelector((state) => state?.usersslice.merchantaccounts?.data);
  const merchants = useSelector((state) => state?.usersslice.merchants?.data);
  const token = user?.access_token;

  const { user_id: id } = useParams();

  const [withdrawDetails, setWithdrawDetails] = useState({
    service: '',
    withdrawal_amount: '',
    transaction_reference: '',
    description: '',
    tenant: '',
    account: '',
  });

  const handleValueChange = (e) => {
    setWithdrawDetails({ ...withdrawDetails, [e.target.name]: e.target.value });
  };
  const handleWithdraw = () => {
    const details = {
      service: withdrawDetails.service,
      withdrawal_amount: withdrawDetails.withdrawal_amount,
      transaction_reference: withdrawDetails.transaction_reference,
      description: withdrawDetails.description,
    };
    const tenant = withdrawDetails.tenant
    const account = withdrawDetails.account
    dispatch(withdraw({ token, details,tenant,account }));
    setOpen(false);
  };

  return (
    <FormGroup>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="withdrawal_amount"
            value={withdrawDetails.withdrawal_amount}
            fullWidth
            size="small"
            label="Withdrawal amount"
            required
            InputLabelProps={{ shrink: true }}
            onChange={handleValueChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="transaction_reference"
            value={withdrawDetails.transaction_reference}
            fullWidth
            size="small"
            label="Transaction reference"
            required
            InputLabelProps={{ shrink: true }}
            onChange={handleValueChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="service">Service</InputLabel>
            <Select
              required
              fullWidth
              labelId="service"
              id="service-select"
              value={withdrawDetails.service}
              label="Float"
              name="service"
              onChange={handleValueChange}
              size="small"
            >
              {services ? (
                services.map((ser) => (
                  <MenuItem key={ser.service_id} value={ser.tenant_service_code}>
                    {ser.tenant_service_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={0}>no services</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            type="text"
            name="description"
            value={withdrawDetails.description}
            fullWidth
            size="small"
            label="Description"
            required
            InputLabelProps={{ shrink: true }}
            onChange={handleValueChange}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="account_type_label">Account</InputLabel>
            <Select
              required
              fullWidth
              labelId="account"
              id="account-select"
              value={withdrawDetails.account}
              label="Type"
              name="account"
              onChange={handleValueChange}
              size="small"
            >
              {merchantaccounts ? (
                merchantaccounts.map((maccounts) => (
                  <MenuItem key={maccounts.tenant_float_account_id} value={maccounts.tenant_float_account_id}>
                    {maccounts.tenant_float_account_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={0}>no accounts</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="currency_label">Tenant</InputLabel>
            <Select
              required
              fullWidth
              labelId="currency_label"
              id="currency-select"
              value={withdrawDetails.tenant}
              label="Float"
              name="tenant"
              onChange={handleValueChange}
              size="small"
            >
              {merchants ? (
                merchants.map((maccounts) => (
                  <MenuItem key={maccounts.tenant_id} value={maccounts.tenant_id}>
                    {maccounts.tenant_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={0}>no accounts</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" onClick={handleWithdraw}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </FormGroup>
  );
};

export default WithdrawModal;
