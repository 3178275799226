import React, { useState } from 'react';
import { Grid, TextField, Button, FormControl, FormGroup, InputLabel, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { topup } from '../redux/slices/sliceActions';
import SnackBar from './snackBar';

const TopupModal = ({ setOpen }) => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.usersslice);
  const merchantaccounts = useSelector((state) => state?.usersslice.merchantaccounts?.data);
  const merchants = useSelector((state) => state?.usersslice.merchants?.data);
  const token = user?.access_token;
  const { user_id: id } = useParams();

  const [depositDetails, setDepositDetails] = useState({
    deposit_amount: '',
    transaction_reference: '',
    tenant: '',
    account: '',
    description: '',
  });

  const handleValueChange = (e) => {
    setDepositDetails({ ...depositDetails, [e.target.name]: e.target.value });
  };
  const handleTopup = () => {
    const details = {
      deposit_amount: depositDetails.deposit_amount,
      transaction_reference: depositDetails.transaction_reference,
      tenant: depositDetails.tenant,
      account: depositDetails.account,
      description: depositDetails.description,
    };
    const tenant = depositDetails.tenant;
    const account = depositDetails.account;
    dispatch(topup({ token, details, tenant, account }));
    setOpen(false);
  };

  return (
    <FormGroup>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="deposit_amount"
            value={depositDetails.deposit_amount}
            fullWidth
            size="small"
            label="Deposit amount"
            required
            InputLabelProps={{ shrink: true }}
            onChange={handleValueChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="transaction_reference"
            value={depositDetails.transaction_reference}
            fullWidth
            size="small"
            label="Transaction reference"
            required
            InputLabelProps={{ shrink: true }}
            onChange={handleValueChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="currency_label">Tenant</InputLabel>
            <Select
              required
              fullWidth
              labelId="currency_label"
              id="currency-select"
              value={depositDetails.tenant}
              label="Float"
              name="tenant"
              onChange={handleValueChange}
              size="small"
            >
              {merchants ? (
                merchants.map((maccounts) => (
                  <MenuItem key={maccounts.tenant_id} value={maccounts.tenant_id}>
                    {maccounts.tenant_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={0}>no accounts</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="account_type_label">Account</InputLabel>
            <Select
              required
              fullWidth
              labelId="account"
              id="account-select"
              value={depositDetails.account}
              label="Type"
              name="account"
              onChange={handleValueChange}
              size="small"
            >
              {merchantaccounts ? (
                merchantaccounts.map((maccounts) => (
                  <MenuItem key={maccounts.tenant_float_account_id} value={maccounts.tenant_float_account_id}>
                    {maccounts.tenant_float_account_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={0}>no accounts</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            type="text"
            name="description"
            value={depositDetails.description}
            fullWidth
            size="small"
            label="Description"
            required
            InputLabelProps={{ shrink: true }}
            onChange={handleValueChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" onClick={handleTopup}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </FormGroup>
  );
};

export default TopupModal;
