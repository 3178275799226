import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  CardHeader,
  Container,
  Typography,
  Divider,
  Stack,
  Button,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import { fetchMerchant,fetchMerchants,fetchMerchantServices, fetchServices, fetchMerchantAccounts } from '../redux/slices/sliceActions';
// hooks

// components

import Iconify from '../components/iconify';
import SnackBar from '../components/snackBar';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { fDate } from '../utils/formatTime';
import Popper from '../components/popper';
import CreateMerchantService from '../components/createMerchantService';
import CreateFloatAccount from '../components/createFloatAccount';
import TopupModal from '../components/TopuupModal';
import WithdrawModal from '../components/WithdrawModal';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));


// ----------------------------------------------------------------------

export default function VendorDeatailsPage({ color = 'primary' }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setshow] = useState(false);
  const { user_id: id } = useParams();

  const { user, error: errorInfo, merchant } = useSelector((state) => state.usersslice);
  const [info, setinfo] = useState('');
  const [showAddService, setshowAddService] = useState(false);
  const [showAddFAccount, setshowAddFAccount] = useState(false);
  const [showTopup, setshowTopup] = useState(false);
  const [showWithdraw, setshowWithdraw] = useState(false);
  const token = user?.access_token;

  const handleAddService = () => {
    setshowAddService(true);
  };

  const handleAddFAccount = () => {
    setshowAddFAccount(true);
  };

  const handleWithdraw = () => {
    setshowWithdraw(true);
  };

  const handleTopup = () => {
    setshowTopup(true);
  };

  useEffect(() => {
    dispatch(fetchMerchant({ token, id }));
    dispatch(fetchMerchants({ page:1,limit:10,token }));
    dispatch(fetchMerchantAccounts({ token, id }));
    dispatch(fetchServices(token));
    const tid  = id
    dispatch(fetchMerchantServices({token,tid}));
  }, [token]);

  return (
    <>
      <Helmet>
        <title> VendorDetails | Kwetu Kash </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="md">
          <Popper size={'sm'} title={'Create Service'} open={showAddService} setOpen={setshowAddService}>
            <CreateMerchantService setOpen={setshowAddService} />
          </Popper>
          <Popper size={'sm'} title={'Create Float Account'} open={showAddFAccount} setOpen={setshowAddFAccount}>
            <CreateFloatAccount setOpen={setshowAddFAccount} />
          </Popper>
          <Popper size={'sm'} title={'Top Up'} open={showTopup} setOpen={setshowTopup}>
            <TopupModal setOpen={setshowTopup} />
          </Popper>
          <Popper size={'sm'} title={'Withdraw'} open={showWithdraw} setOpen={setshowWithdraw}>
            <WithdrawModal setOpen={setshowWithdraw} />
          </Popper>
          <Card sx={{ mb: 2 }}>
            <CardHeader title={merchant?.tenant_name} subheader={merchant?.tenant_email_address} />

            <Scrollbar>
              <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                  Phone: {merchant?.tenant_phone_number}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                  Business pin: {merchant?.tenant_business_pin}
                </Typography>
                <Typography variant="body2" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
                  Domain: {merchant?.tenant_domain}
                </Typography>
              </Stack>
            </Scrollbar>

            <Divider />
          </Card>

          <Box
            className="greetings"
            sx={{
              my: 2,
              display: 'flex',
              alignItems: { xs: 'flex-start', lg: 'center' },
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                fontFamily="'Karla', sans serif"
                fontWeight="600"
                fontSize="1.8rem"
                color="#00695c"
                variant="h4"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow={'ellipsis'}
              >
                Services
              </Typography>
            </Box>

            <List
              sx={{
                alignItems: { xs: 'flex-end', lg: 'center' },
                justifyContent: 'space-between',
                maxWidth: '700px',
                gap: { xs: '20px', lg: '40px' },
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' },
              }}
            >
              <ListItem className="btn-services">
                <Button
                  onClick={handleAddService}
                  variant="contained"
                  sx={{ backgroundColor: 'teal' }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New service
                </Button>
              </ListItem>
            </List>
          </Box>

          <Grid container spacing={3}>
            {merchant?.tenant_services?.map((m, index) => (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    boxShadow: 0,
                    color: (theme) => theme.palette[color].darker,
                    bgcolor: (theme) => theme.palette[color].lighter,
                  }}
                >
                  <Stack spacing={2} sx={{ p: 2 }}>
                    <Typography variant="subtitle2" noWrap style={{ color: '#004d40' }}>
                      {m.tenant_service_name}
                    </Typography>

                    <Stack justifyContent="space-between">
                      <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                        Code: {m.tenant_service_code}
                      </Typography>
                      <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                        Status: {m.is_active === 1 ? 'Active' : 'Inactive'}
                      </Typography>
                      <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                        Created: {fDate(m.created_at)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box
            className="greetings"
            sx={{
              my: 2,
              display: 'flex',
              alignItems: { xs: 'flex-start', lg: 'center' },
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                fontFamily="'Karla', sans serif"
                fontWeight="600"
                fontSize="1.8rem"
                color="#00695c"
                variant="h4"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow={'ellipsis'}
              >
                Accounts
              </Typography>
            </Box>

            <List
              sx={{
                alignItems: { xs: 'flex-end', lg: 'center' },
                justifyContent: 'space-between',
                maxWidth: '700px',
                gap: { xs: '20px', lg: '40px' },
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' },
              }}
            >
              <ListItem className="btn-accounts">
                <Button
                  onClick={handleAddFAccount}
                  variant="contained"
                  sx={{ backgroundColor: 'teal' }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New account
                </Button>
              </ListItem>
            </List>
          </Box>

          <Grid container spacing={3}>
            {merchant?.accounts?.map((acc, index) => (
              <Grid key={index} item xs={12} sm={6} md={6}>
                <Card
                  sx={{
                    boxShadow: 0,
                    color: (theme) => theme.palette[color].darker,
                    bgcolor: (theme) => theme.palette[color].lighter,
                  }}
                >
                  <Stack spacing={2} sx={{ p: 2 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="subtitle2" noWrap style={{ color: '#004d40' }}>
                      {acc.tenant_float_account_name}
                    </Typography>
                    {
                      acc.account_type === "FLOAT" ? (
                        <Button
                          onClick={handleTopup}
                          variant="contained"
                          sx={{ backgroundColor: 'teal' }}
                          startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                          Deposit
                        </Button>
                      ):
                      <Button
                          onClick={handleWithdraw}
                          variant="contained"
                          sx={{ backgroundColor: 'teal' }}
                          startIcon={<Iconify icon="eva:minus-outline" />}
                        >
                          Withdraw
                        </Button>

                    }
                    </Stack >
    
                    <Stack justifyContent="space-between">
                      <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                        Type: {acc.account_type}
                      </Typography>
                      <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                        Status: {acc.is_active === 1 ? 'Active' : 'Inactive'}
                      </Typography>
                      <Typography variant="subtitle2" noWrap style={{ color: '#00796b' }}>
                        Balance: {acc.running_balance}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <SnackBar value={show} text={info} mood={errorInfo >= 200 && errorInfo < 300 ? 'success' : 'error'} />
      </StyledRoot>
    </>
  );
}
